import Modal from '../../../_app/components/Modal'
import {CloseIcon} from '../../../_app/components/Icons'
import {Box, Link, Text} from '@chakra-ui/react'
import React from 'react'
import Logo from '../../../_app/components/Logo'

const DialogHomePage = (props): any => {
  const { onClose } = props
  const style = {
    box: {
      border: '4px solid #dd3f3f',
      padding: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    text: {
      marginBottom: '15px',
      fontWeight: '600',
      fontSize: '22px',
      lineHeight: '22px',
      color: '#dd3f3f',
      textAlign: 'center',
    },
    textSpace: {
      marginBottom: '30px',
      fontWeight: '600',
      fontSize: '22px',
      lineHeight: '22px',
      color: '#dd3f3f',
      textAlign: 'center',
    },
    textSpaceBetween: {
      marginTop: '30px',
      marginBottom: '30px',
      fontWeight: '600',
      fontSize: '22px',
      lineHeight: '22px',
      color: '#dd3f3f',
      textAlign: 'center',
    },
    logo: {
      width: '100%',
      marginTop: '30px !important',
      maxWidth: '100px',
      margin: '0 auto',
      color: '#dd3f3f',
    }
  }
  return (
    <Modal
      isOpen
      onClose={() => onClose()}
      position="relative"
      maxWidth="720px"
    >
      <CloseIcon
        position="absolute"
        top="32px"
        right="32px"
        cursor="pointer"
        onClick={() => onClose()}
      />
      <Box sx={style.box}>
        <Text sx={style.text}>
          Kochani,
        </Text>
        <Text  sx={style.textSpace}>
          Dziękujemy, że wybraliście Lilou.
        </Text>
        <Text sx={style.text}>
          W związku ze wzmożonym zainteresowaniem
        </Text>
        <Text sx={style.text}>
          zakupami w naszym butiku online
        </Text>
        <Text sx={style.text}>
          czas ładownia na stronie może się wydłużyć.
        </Text>
        <Text sx={style.text}>
          Przepraszamy za niedogodności
        </Text>
        <Text sx={style.text}>
          i serdecznie dziękujemy za cierpliwość.
        </Text>
        <Text sx={style.textSpaceBetween}>
          Pozostajemy do Waszej dyspozycji.
        </Text>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', maxWidth: '200px', margin: '0 auto'}}>
          <Link href={'tel:+48224604777'} sx={style.text}>
            +48 224 604 777
          </Link>
          <Link href={'mailto:e-sklep@lilou.pl'} sx={style.text}>
            e-sklep@lilou.pl
          </Link>
        </Box>
        <Logo sx={style.logo} />
        {/* <SimpleGrid columns={{ base: 2 }} spacing={4}>*/}
        {/*  <Box/>*/}
        {/*  <Button onClick={() => handleAdd()} width="full" sx={{ marginTop: '20px'}}>*/}
        {/*    {textAdd || (addT && t(addT)) || t('CLOSE')}*/}
        {/*    {*/}
        {/*        lockedYesDelete && (*/}
        {/*            <CircularProgress sx={{marginLeft: '20px', transform: 'scale(.7)'}} isIndeterminate/>*/}
        {/*        )*/}
        {/*    }*/}
        {/*  </Button>*/}
        {/* </SimpleGrid>*/}
      </Box>
    </Modal>
  )
}

export default DialogHomePage
