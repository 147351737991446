import { Box } from '@chakra-ui/react'
import { ComponentSectionsInstashop } from '_app/types/cms'

export const SectionsInstashop = ({
  section,
}: {
  section: ComponentSectionsInstashop;
}) => {
  if (!section.body_script) return null

  return (
    <Box position="relative" zIndex={0}>
      <div
        dangerouslySetInnerHTML={{
          __html: section.body_script,
        }}
        suppressHydrationWarning={true}
      />
    </Box>
  )
}
