import {
  Box,
  Text,
  Flex,
  Heading,
  Container,
  AspectRatio,
} from '@chakra-ui/react'
import { Button } from '_app/components'
import ImageCms from '_app/components/Content/ImageCms'
import { FC } from 'react'

const Newsletter: FC<any> = ({
  cta,
  img,
  img_mobile,
  alt,
  title,
  description,
}) => {
  const imgRatio = { base: 320 / 420, md: 1920 / 420 }
  return (
    <Box position="relative" w="100%" bottom="-7px">
      {img ? (
        <Box display={{ base: 'none', md: 'block' }}>
          <ImageCms
            src={''}
            attributes={img.data.attributes}
            alt={alt}
          />
        </Box>
      ) : null}

      {img_mobile ? (
        <Box display={{ base: 'block', md: 'none' }}>
          <AspectRatio ratio={imgRatio} w="full">
            <ImageCms
              src={''}
              attributes={img_mobile.data.attributes}
              layout="fill"
              alt={alt}
            />
          </AspectRatio>
        </Box>
      ) : null}
      <Flex
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={9}
        alignItems="center"
        justifyContent="center"
      >
        <Container color="white" textAlign="center">
          <Flex direction="column" alignItems="center">
            <Heading mb={{ base: '1.5rem', md: '2rem' }}>{title}</Heading>
            <Text mb="1.5rem">{description}</Text>

            {cta && (
              <Button
                width={{ base: '100%', md: 'auto' }}
                fontSize="sm"
                id={`button-${cta.id}`}
              >
                {cta.text}
              </Button>
            )}
          </Flex>
        </Container>
      </Flex>
    </Box>
  )
}

export default Newsletter
