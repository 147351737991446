import {Box, Container, Text} from '@chakra-ui/react'
import {useEffect, useState} from 'react'
import {motion} from 'framer-motion'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import {useRouter} from 'next/router'
import {GTM_EVENTS} from '../../../_app/utils/dataLayer'

const BannerPromotion = ({title, subTitle, startDate, endDate, background, link, productView = false}) => {
    const router = useRouter()
    const [today, setToday] = useState(new Date())
    const [start] = useState(new Date(startDate))
    const [end]: any = useState(new Date(endDate))
    const [hasMounted, setHasMounted] = useState(false)
    const style = {
        root: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            backgroundColor: background || 'white',
            padding: '10px',
            cursor: 'pointer',
        },
        title: {
            color: '#ffffff',
            fontSize: '20px',
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: '5px',
        }
    }
    const calculateTimeLeft = () => {
        const diff = end - (new Date() as any)
        if (diff <= 0) {
            return {days: 0, hours: 0, minutes: 0, seconds: 0}
        }
        return {
            days: Math.floor(diff / (1000 * 60 * 60 * 24)),
            hours: Math.floor((diff / (1000 * 60 * 60)) % 24),
            minutes: Math.floor((diff / (1000 * 60)) % 60),
            seconds: Math.floor((diff / 1000) % 60)
        }
    }
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft())
    useEffect(() => {
        if (!hasMounted) return
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft())
            setToday(new Date())
        }, 1000)
        return () => clearInterval(timer)
    }, [endDate, hasMounted])
    useEffect(() => {
        setHasMounted(true)
    }, [])
    if (!hasMounted) return null
    return (
        <>
            {
                today >= start && end > today ? (
                    <Container my={{base: productView ? BreakpointsHelper?.isMobile() ? '0.5rem' : '2rem' : '2rem'}}
                               mx={{base: productView ? '0' : 'auto'}} px={{base: productView ? '0' : '16px'}}
                               width={productView ? 'calc(100% - 5px)' : '100%'} overflow="hidden"
                               paddingRight={productView ? BreakpointsHelper.isMobile() ? '0' : '25px' : 'auto'}>
                        <Box
                            sx={style.root}
                            onClick={() => {
                                GTM_EVENTS.promotionClick({
                                    ecommerce: {
                                        promoClick: {
                                            promotions: [
                                                {
                                                    name: 'Banner Promotion Clock',
                                                    position: 0,
                                                    place: productView ? 'productView' : 'main page',
                                                }
                                            ]
                                        }
                                    }
                                })
                                router.push(link)
                            }}
                        >
                            <Text sx={style.title}>{title}</Text>
                            <Box display="flex">
                                {[['days', 'D'], ['hours', 'G'], ['minutes', 'M'], ['seconds', 'S']].map(([key, label], index) => (
                                    <Box key={key} display="flex"
                                         fontSize={BreakpointsHelper.isMobile() ? '22px' : '28px'} color="white"
                                         fontWeight="bold" alignItems="center" justifyContent="space-around"
                                         flexDirection="column" textAlign="center">
                                        <Text className={'animationFadeIn'}
                                              fontFamily={'Courier New, monospace !important'}
                                              borderLeft={index !== 0 ? '2px solid #fff' : ''}
                                              width={'80px'}
                                              padding={BreakpointsHelper.isMobile() ? '0 15px' : '0 15px'}
                                        >
                                            <motion.div
                                                key={timeLeft[key]}
                                                initial={{ fontFamily: 'Courier New, monospace !important' }}
                                                animate={{animation: 'fadeInTopToBottom .5s forwards'}}
                                            >
                                                {timeLeft[key].toString().padStart(2, '0')}
                                            </motion.div>
                                        </Text>
                                        <Text marginBottom="5px" marginTop="5px"
                                              fontSize={BreakpointsHelper.isMobile() ? '16px' : '14px'}
                                              textAlign="center">{label}</Text>
                                    </Box>
                                ))}
                            </Box>
                            <Text color="white" fontSize="18px" textAlign="center">{subTitle}</Text>
                        </Box>
                    </Container>
                ) : ''
            }
        </>
    )
}

export default BannerPromotion
