import { Fragment } from 'react'
import { HomePageSectionsDynamicZone, Maybe } from '_app/types/cms'
import { getComponent } from './getComponent'
import { getComponentKey } from './getComponentKey'

type Props = {
  sections: Maybe<HomePageSectionsDynamicZone>[];
  mobileVideoUrl: string;
  mobileVideoCopy: string;
};

export const BodySections = ({
  sections,
  mobileVideoCopy,
  mobileVideoUrl,
}: Props) => {
  return (
    <>
      {
        sections?.map(section => (
          <Fragment key={getComponentKey(section)}>{getComponent(section, mobileVideoCopy, mobileVideoUrl)}</Fragment>
        ))
      }
    </>
  )
}
