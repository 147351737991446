import {Box, Container} from '@chakra-ui/react'
import {useRouter} from '../../../_app/hooks/useRouter'
import URLHelper from '../../../helpers/URLHelper/URLHelper'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import Image from 'next/image'
import icon1 from './Icon_Kontakt.png'
import icon2 from './Icon_Versand.png'
import icon3 from './Icon_Bezahlung.png'
import icon4 from './Icon_Konfigurator.png'

const TrustBanner = () => {
    const router = useRouter()
    const { locale } = router
    const style = {
        root: {
            display: 'flex',
            marginTop: '96px',
            flexDirection: BreakpointsHelper.isMobile() ? 'column' : 'row',
        },
        box: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            cursor: 'pointer',
            alignItems: 'center',
            justifyContent: 'center',
            padding: BreakpointsHelper.isMobile() ? '30px' : '5px',
            marginBottom: BreakpointsHelper.isMobile() ? '20px' : '0',
            textAlign: 'center',
        },
        image: {
          marginBottom: '5px',
          width: '80px',
        },
    }
    return (
        <Container overflow="hidden">
            <Box sx={style.root}>
                <Box sx={style.box} onClick={() => router.push(URLHelper.createURL({ href: '/contact', locale}))}>
                    <Image src={icon1} alt={'icon 1'} style={style.image} />
                    <span>HILFE & KONTAKT</span>
                </Box>
                <Box sx={style.box}
                     onClick={() => router.push(URLHelper.createURL({href: '/information/lieferung', locale}))}>
                    <Image src={icon2} alt={'icon 1'} style={style.image} />
                    <span>GRATIS VERSAND AB 100 € EINKAUFSWERT</span>
                </Box>
                <Box sx={style.box}
                     onClick={() => router.push(URLHelper.createURL({href: '/information/zahlungsmethoden', locale}))}>
                    <Image src={icon3} alt={'icon 1'} style={style.image} />
                    <span>SICHERE BEZAHLUNG</span>
                </Box>
                <Box sx={style.box}
                     onClick={() => router.push(URLHelper.createURL({href: '/armband-mit-vergoldetem-kleeblatt-etincelle-am-duennen-roten-schnur', locale}))}>
                    <Image src={icon4} alt={'icon 1'} style={style.image} />
                    <span>SCHMUCK-KONFIGURATOR</span>
                </Box>
            </Box>
        </Container>
    )
}

export default TrustBanner