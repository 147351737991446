import React from 'react'
import { Box, Container, Heading } from '@chakra-ui/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import ProductsCarousel from 'modules/Listing/ProductsCarousel'
import Item from 'modules/Listing/Item'
import {
  BundleProduct,
} from '_app/graphql/generated/graphql'
import { ComponentPartsProduct } from '_app/types/cms'
import { WithAnalyticsId } from '_app/types/dataLayer'
import {useGetProductsListQuery} from '../../_app/graphql/queries/category/GetProductsList'

interface HomepageProductsProps {
  title?: string;
  items?: Array<ComponentPartsProduct | undefined>;
  rows?: number;
}

const HomepageProducts = ({
  title,
  items = [],
  rows = 1,
}: HomepageProductsProps) => {
  const urlKeys = items
    .map(item => item?.url_key)
    .filter((url): url is string => Boolean(url))

  const { loading, data: productsData } = useGetProductsListQuery({
    variables: {
      pageSize: 16,
      currentPage: 1,
      filter: { url_key: { in: urlKeys } },
    },
  })
  if (loading) return null

  const products = ((productsData?.products?.items as BundleProduct[]) || [])
    .slice()
    .sort(
      (a, b) =>
        urlKeys.indexOf(a.url_key || '') - urlKeys.indexOf(b.url_key || '')
    )
    .map<WithAnalyticsId<BundleProduct>>((item, i) => ({
      ...item,
      analyticsId: `HP_${title}_${i + 1}`,
    }))

  const [mainItem, ...restItems] = products

  return (
    <Box
      my={{
        base: '3rem',
        md: '6rem',
      }}
    >
      <Box
        display={{
          base: 'block',
          lg: 'none',
        }}
      >
        <Container pr={{ base: 0, md: '1rem' }}>
          {title && (
            <Heading as="h2" fontSize={{ base: '1.5rem', md: '2.8125rem' }}>
              {title}
            </Heading>
          )}
          {mainItem && (
            <Box>
              <Item
                item={mainItem}
                boxProps={{ mb: 7, pr: { base: '1rem', md: 'unset' } }}
              />
            </Box>
          )}
          <Swiper slidesPerView={1.5} spaceBetween={30}>
            {restItems?.map(item => (
              <SwiperSlide key={item.sku}>
                <Item item={item} hideCart={true} />
              </SwiperSlide>
            ))}
          </Swiper>
        </Container>
      </Box>
      <Box
        display={{
          base: 'none',
          lg: 'block',
        }}
      >
        <ProductsCarousel
            hideCart={true}
          {...{
            title,
            titleProps: {
              textStyle: 'pageHeader',
            },
            items: products,
            rows,
          }}
        />
      </Box>
    </Box>
  )
}

export default HomepageProducts
