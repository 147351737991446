import {Box} from '@chakra-ui/react'
import {useMemo} from 'react'
import {Autoplay, Pagination} from 'swiper'
import {Hero} from '_app/components/Banner'
import {BANNER_ALIGNMENT} from '_app/components/Banner/types'
import Carousel from '_app/components/Carousel'
import {ComponentSectionsBanner} from '_app/types/cms'
import {GTM_EVENTS} from '_app/utils/dataLayer'
import ImageEnum from '../../enums/ImageEnum/ImageEnum'
import ElementsSizesEnum from '../../enums/ElementsSizesEnum/ElementsSizesEnum'

type Props = {
  hero: ComponentSectionsBanner[];
};

export const HeroSection = ({ hero }: Props) => {
  const onSlideChange = (activeSlide: number) => {
    GTM_EVENTS.promotionView({
      ecommerce: {
        promoView: {
          promotions: [
            {
              name: hero[activeSlide].title || '',
              position: activeSlide + 1,
            },
          ],
        },
      },
    })
  }

  const handleCTAClick = (item: ComponentSectionsBanner, index: number) => {
    GTM_EVENTS.promotionClick({
      ecommerce: {
        promoClick: {
          promotions: [
            {
              name: item?.title ?? '',
              position: index + 1,
            },
          ],
        },
      },
    })
  }
  const slides = useMemo(
    () =>
      hero?.map((item, idx) => {
        const heroProps = {
          ...item,
          alignment: BANNER_ALIGNMENT.LEFT,
          short_description: item.short_description || '',
          position: idx + 1,
          creative: 'Main Page Carousel',
          cta: {
            ...item?.cta,
            onClick: () => handleCTAClick(item, idx),
          },
          title: item.title,
        }
        return {
          id: idx,
          content: (
            <Hero
              {...heroProps}
              priority={idx === 0}
              imgRatio={ImageEnum.HERO_IMAGE_RATIO}
            />
          ),
        }
      }),
    [hero]
  )

  return (
    <Box mt={{ lg: `-${ElementsSizesEnum.NAVBAR_HEIGHT.lg}` }}>
      <Carousel
        modules={[Pagination, Autoplay]}
        slidesPerView={1}
        pagination={{ clickable: true }}
        longSwipes={false}
        speed={400}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        slides={slides}
        onSwiper={swiper => onSlideChange(swiper.activeIndex)}
        onSlideChange={swiper => onSlideChange(swiper.activeIndex)}
      />
    </Box>
  )
}
