import { HomePageSectionsDynamicZone } from '_app/types/cms'

export const getComponentKey = (
  section: HomePageSectionsDynamicZone | undefined
) => {
  if (!section?.__typename) return null
  const id = 'id' in section ? section.id : section.message

  return section.__typename + id
}
