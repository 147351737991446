import VideoSection from '_app/components/VideoSection'
import {ImageBanner} from '_app/components/Banner'
import ContentSlider from '_app/components/Content/ContentSlider'
import {HomePageSectionsDynamicZone} from '_app/types/cms'
import HomepageProducts from '../HomepageProducts'
import {SectionsGrid} from './SectionsGrid'
import {SectionsInstashop} from './SectionsInstashop'
import ImageEnum from '../../../enums/ImageEnum/ImageEnum'

export const getComponent = (
  section: HomePageSectionsDynamicZone | undefined,
  mobileVideoCopy: string,
  mobileVideoUrl: string
) => {
  if (!section?.__typename) return null

  switch (section.__typename) {
    case 'ComponentSectionsGrid': {
      return <SectionsGrid section={section} />
    }
    case 'ComponentSectionsVideo': {
      return (
        <VideoSection
          copyDesktop={section.text}
          desktopUrl={section.url}
          mobileUrl={mobileVideoUrl}
        />
      )
    }
    case 'ComponentSectionsBanner': {
      return <ImageBanner imgRatio={ImageEnum.BANNER_IMAGE_RATIO} {...section} />
    }
    case 'ComponentSectionsSlider': {
      return <ContentSlider items={section.items} />
    }
    case 'ComponentSectionsInstashop': {
      return <SectionsInstashop section={section} />
    }
    case 'ComponentSectionsProductsList': {
      return (
        <HomepageProducts
          title={section.section_title}
          items={section.items}
          rows={section.rows}
        />
      )
    }
    default:
      return null
  }
}
