import { useMemo } from 'react'
import { ComponentSectionsBanner, Query } from '_app/types/cms'
import { AboutSlide } from '../CarouselAbout'

export const useHomePage = (cms: Query) => {
  const hero = useMemo(
    () =>
      (cms?.homePage?.data?.attributes?.hero || []).filter(
        (item): item is ComponentSectionsBanner => !!item
      ),
    [cms]
  )

  const opinions: AboutSlide[] = useMemo(
    () =>
      (cms?.homePage?.data?.attributes?.opinions?.items || []).map(opinion => ({
        description: opinion?.text,
        logo: opinion?.logo,
      })),
    [cms]
  )

  const newsletter = cms?.newsletterBanner?.data?.attributes
  const sections = cms?.homePage?.data?.attributes?.sections || []

  const mobileVideoUrl =
    cms?.homePage?.data?.attributes?.video_mobile?.url || ''
  const mobileVideoCopy =
    cms?.homePage?.data?.attributes?.video_mobile?.text || ''

  return {
    hero,
    opinions,
    newsletter,
    sections,
    mobileVideoUrl,
    mobileVideoCopy,
  }
}
