import React, {FC, useState} from 'react'
import {Box, Container, Flex, Heading, IconButton, Text} from '@chakra-ui/react'
import {Swiper as SwiperClass} from 'swiper/types'
import {Swiper, SwiperSlide} from 'swiper/react'
import {Autoplay, Controller} from 'swiper'
import theme from 'theme'
import {ChevronLeftIcon, ChevronRightIcon} from '_app/components/Icons'
import 'swiper/css'
import {ImgProps} from '_app/types/content'
import ImageCms from '_app/components/Content/ImageCms'
import {useTranslation} from 'next-i18next'

interface ChevronButtonProps {
  onClick: () => void;
  icon: React.ReactElement;
  label: string;
}

const ChevronButton = ({ onClick, icon, label }: ChevronButtonProps) => (
  <IconButton
    display={{
      base: 'none',
      lg: 'block',
    }}
    aria-label={label}
    variant="icon"
    icon={icon}
    p={0}
    onClick={onClick}
    zIndex={1}
  />
)

export interface AboutSlide {
  description?: string;
  logo?: ImgProps;
}

interface AboutSectionProps {
  slides: AboutSlide[];
}

const About: FC<AboutSectionProps> = ({ slides }) => {
  const { t } = useTranslation()
  const [firstSwiper, setFirstSwiper] = useState<SwiperClass>()
  const [secondSwiper, setSecondSwiper] = useState<SwiperClass>()
  const style = {
    box: {
      position: 'relative',
      width: '100%',
      paddingBottom: '100px'
    }
  }
  return (
    <Box textAlign="center" bg={theme.colors.main} py="130px">
      <Heading fontSize={{ base: '24px', md: '45px' }} mb={8}>
        {t('WHO_IS_TALKING_ABOUT_LILOU')}
      </Heading>
      <Container as={Flex} mb="42px">
        <ChevronButton
          icon={<ChevronLeftIcon fontSize="40px" />}
          label="previous"
          onClick={() => firstSwiper?.slidePrev()}
        />
        <Swiper
          loop
          loopedSlides={1}
          slidesPerView={1}
          modules={[Autoplay, Controller]}
          onSwiper={setFirstSwiper}
          controller={{ control: secondSwiper }}
          speed={700}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
          }}
        >
          {slides?.map((slide, idx) => (
            <SwiperSlide key={`aboutdescription[${idx}]`}>
              <Text fontSize={{ base: '16px', md: '24px' }} cursor="pointer">
                {slide.description}
              </Text>
            </SwiperSlide>
          ))}
        </Swiper>
        <ChevronButton
          icon={<ChevronRightIcon fontSize="40px" />}
          label="next"
          onClick={() => firstSwiper?.slideNext()}
        />
      </Container>

      <Box
        maxW={{
          base: 'unset',
          lg: '700px',
        }}
        mx="auto"
      >
        <Swiper
          loop
          loopedSlides={1}
          centeredSlides
          slideToClickedSlide
          slidesPerView={1}
          modules={[Controller]}
          onSwiper={setSecondSwiper}
          controller={{ control: firstSwiper }}
          speed={700}
          allowTouchMove={false}
        >
          {slides?.map((slide, idx) => {
            return slide?.logo ? (
              <SwiperSlide key={`about[${idx}]`}>
                <Box sx={style.box}>
                  <ImageCms
                    src={''}
                    attributes={slide.logo.data?.attributes}
                    layout="fill"
                    alt="logo"
                  />
                </Box>
              </SwiperSlide>
            ) : null
          })}
        </Swiper>
      </Box>
    </Box>
  )
}

export default About
