import { Container, Heading } from '@chakra-ui/react'
import BannerGrid from '_app/components/BannerGrid'
import { ComponentSectionsGrid } from '_app/types/cms'

export const SectionsGrid = ({
  section,
}: {
  section: ComponentSectionsGrid;
}) => {
  return (
    <Container
      my={{
        base: '2rem',
      }}
      pr={{ base: 0, md: '1rem' }}
    >
      {section.section_title && (
        <Heading as="h2" fontSize={{ base: '1.5rem', md: '2.8125rem' }}>
          {section.section_title}
        </Heading>
      )}
      <BannerGrid firstSmall items={section.items} id={`HP_${section.id}`} />
    </Container>
  )
}
